@use '../../th-common/scss/main';

:root {
  --app-loader-size: 40px;
  --mat3-button-font-size: 16px;
  --mat3-button-font-weight: 600;
  --app-section-header-padding: 12px 8px 12px 20px;
}

.register-consumer-claim {
  &__files-preview-title {
    margin-bottom: 16px;
    font-weight: 600;
    line-height: 1.5;
  }

  &__files-preview-requirement {
    font-size: 16px;
    line-height: 1.5;
    letter-spacing: .15px;
  }

  &__photos-receipt-info {
    display: flex;
    gap: 12px;
    align-items: center;
    margin-bottom: 12px;
  }

  &__important-block {
    display: flex;
    gap: 12px;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
    color: #8a9918;
  }
}
